import React from 'react';

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%',
  height: '30px',
  backgroundColor: '#f5a623',
  lineHeight: 0,
};

const textStyle = {
  marginLeft: '5px',
  fontWeight: 'bold',
  fontSize: '18px',
};

const PreviewBanner = () => (
  <div style={containerStyle}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src="/media/exclamation.svg" alt="" height={20} width={20} />
      <span style={textStyle}>This is a preview link.</span>
    </div>
  </div>
);

export default PreviewBanner;
