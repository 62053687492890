/* global MathJax */
import React from 'react';
import PropTypes from 'prop-types';
import loadScript from 'load-script';

/**
 * Context for loading MathJax
 */
class Context extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
    this.onLoad = this.onLoad.bind(this);
  }

  getChildContext() {
    return {
      MathJax: typeof MathJax === 'undefined' ? undefined : MathJax,
      input: this.props.input,
    };
  }

  componentDidMount() {
    // const script = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML';
    const script = '/MathJax/MathJax.js?config=TeX-MML-AM_CHTML';
    loadScript(script, this.onLoad);
  }

  onLoad() {
    const { options } = this.props;
    MathJax.Ajax.config.path.Public = '/MathJax';
    options.CommonHTML = { scale: 200 };
    options.showMathMenu = false;
    options.extensions = ['tex2jax.js', '[Public]/forminput.js'];
    options.jax = ['input/TeX', 'output/HTML-CSS'];
    //  This enables inlineMath which might affect other fields starting with $
    // See: https://docs.mathjax.org/en/latest/start.html
    // options.tex2jax = { inlineMath: [['$', '$'], ['\\(', '\\)']] };

    MathJax.Hub.Config(options);

    MathJax.Hub.Register.StartupHook('End', () => {
      MathJax.Hub.processSectionDelay = this.props.delay;

      if (this.props.didFinishTypeset) {
        this.props.didFinishTypeset();
      }
    });

    MathJax.Hub.Register.MessageHook('Math Processing Error', (message) => {
      if (this.props.onError) {
        this.props.onError(MathJax, message);
      }
    });

    if (this.props.onLoad) {
      this.props.onLoad();
    }

    this.setState({
      loaded: true,
    });
  }

  render() {
    if (!this.state.loaded && !this.props.noGate) {
      return this.props.loading;
    }

    const { children } = this.props;

    return React.Children.only(children);
  }
}

Context.propTypes = {
  children: PropTypes.node.isRequired,
  input: PropTypes.oneOf(['ascii', 'tex']),
  delay: PropTypes.number,
  options: PropTypes.object,
  loading: PropTypes.node,
  noGate: PropTypes.bool,
  didFinishTypeset: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

Context.childContextTypes = {
  MathJax: PropTypes.object,
  input: PropTypes.string,
};

Context.defaultProps = {
  input: 'ascii',
  delay: 0,
  options: {},
  loading: null,
  noGate: false,
  didFinishTypeset: () => {},
  onError: () => {},
  onLoad: () => {},
};

export default Context;
