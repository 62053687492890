import React from 'react';
import PropTypes from 'prop-types';


class Question extends React.Component {
  constructor(props, context) {
    super(props, context);

    const { options, hashid } = this.props;
    const stateOptions = [];
    let index = 1;

    options.forEach((option) => {
      stateOptions.push({
        question_hashid: hashid,
        text: option,
        state: 'neutral',
        id: index += 1,
      });
    });

    this.state = {
      // This is used in components that extends this class
      // eslint-disable-next-line react/no-unused-state
      ready: false,
      answer: false,
      options: stateOptions,
    };
  }

  selectOption(i) {
    if (this.state.answer) {
      return;
    }

    const { options } = this.state;

    options.forEach((option, index) => { options[index].state = 'neutral'; });

    options[i].state = 'selected';

    this.setState({
      options,
      // This is used in components that extends this class
      // eslint-disable-next-line react/no-unused-state
      ready: true,
    });
  }

  submit() {
    const { options } = this.state;
    const i = options.findIndex(option => option.state === 'selected');
    const answerText = options[i].text;
    const answer = answerText === this.props.correct_answer[0] ? 'correct' : 'incorrect';

    options[i].state = answer;

    // Highlight correct option
    if (answer === 'incorrect') {
      const j = options.findIndex(option => option.text === this.props.correct_answer[0]);

      if (j !== -1) {
        options[j].state = 'correct';
      }
    }

    this.saveAnswer({
      answer: answerText,
      isCorrect: answer === 'correct',
    });

    this.setState({
      answer,
      options,
    });
  }

  saveAnswer(answer) {
    this.props.saveAnswer({
      question_hashid: this.props.hashid,
      answer: answer.answer,
      is_correct: answer.isCorrect,
    });
  }
}

Question.propTypes = {
  options: PropTypes.string.isRequired,
  hashid: PropTypes.string.isRequired,
  correct_answer: PropTypes.string.isRequired,
  saveAnswer: PropTypes.func.isRequired,
};

export default Question;
