import React from 'react';
import Routes from './routes';
import ImportProductStyle from './components/ImportProductStyle';

// BASE SCSS
import './sass/base.scss';

export default () => (
  <>
    <ImportProductStyle />
    <Routes />
  </>
);
