import {
  shape, string, arrayOf, instanceOf,
} from 'prop-types';

export const questionShape = shape({
  hashid: string.isRequired,
  type: string.isRequired,
  instructions: string.isRequired,
  correct_answer: instanceOf(Array).isRequired,
  human_readable_correct_answer: string.isRequired,
  options: instanceOf(Array).isRequired,
  prompt: string,
  hint: string,
  audio_track: string,
  audio_transcript: string,
  image: string,
  alt_tag: string,
  equation: string,
});

export const quizShape = shape({
  chapter_name: string.isRequired,
  chapter_slug: string.isRequired,
  product_slug: string.isRequired,
  quiz_slug: string.isRequired,
  title: string.isRequired,
  type: string.isRequired,
  questions: arrayOf(questionShape),
});
