import React from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';

import AudioPlayerControls from './AudioPlayer/AudioPlayerControls';

class Audio extends React.Component {
  static formatTime(time) {
    const minutes = Math.floor(time / 60).toString();
    const seconds = Math.floor(time - (minutes * 60)).toString();

    return `${minutes.length < 2 ? '0' : ''}${minutes}:${seconds.length < 2 ? '0' : ''}${seconds}`;
  }

  constructor(props, context) {
    super(props, context);
    this.playPause = this.playPause.bind(this);

    this.state = {
      playing: false,
      percentage: 0,
      time: Audio.formatTime(0),
      audioClipDuration: 0,
    };

    this.audioPlayer = React.createRef();
  }

  componentDidMount() {
    const audioPlayer = this.audioPlayer.current;

    audioPlayer.addEventListener('loadedmetadata', () => this.setState({
      audioClipDuration: audioPlayer.duration,
      time: Audio.formatTime(audioPlayer.duration),
    }));

    audioPlayer.addEventListener('ended', () => this.setState({ playing: false }));

    audioPlayer.addEventListener('timeupdate', () => {
      const percentage = Math.round((100 / audioPlayer.duration) * audioPlayer.currentTime);

      this.setState({
        percentage,
        time: Audio.formatTime(audioPlayer.currentTime),
      });
    });
  }

  playPause() {
    const audioPlayer = this.audioPlayer.current;
    if (audioPlayer.paused || !audioPlayer.currentTime) {
      audioPlayer.play();
      ReactGA.event({
        category: 'playPause',
        action: 'Playing Audio',
      });

      this.setState({ playing: true });
    } else {
      audioPlayer.pause();

      this.setState({ playing: false });
    }
  }

  scrub(percentage) {
    const { audioClipDuration } = this.state;
    const audioPlayer = this.audioPlayer.current;
    const newTime = ((audioClipDuration * percentage) / 100);
    this.setState({
      time: Audio.formatTime(newTime),
    });
    audioPlayer.currentTime = newTime;
  }

  render() {
    return (
      <div className={`
        audio
        ${this.props.small ? ' audio--small' : ''}
        `}
      >
        <audio src={this.props.uri} ref={this.audioPlayer}>
          <track />
        </audio>
        <div
          className={`
          audio__play
          ${this.state.playing ? ' audio__play--playing' : ''}
          `}
          role="presentation"
          onClick={this.playPause}
        />

        { this.props.small ? (
          <div
            className={`
            audio__wave
            ${this.state.playing ? ' audio__wave--playing' : ''}
            `}
            role="presentation"
            onClick={this.playPause}
          />
        ) : (
          <AudioPlayerControls
            scrubHandler={event => this.scrub(event)}
            percentage={this.state.percentage}
          />
        ) }

        { !this.props.small ? <div className="audio__time">{ this.state.time }</div> : '' }
      </div>
    );
  }
}

Audio.propTypes = {
  small: PropTypes.bool,
  uri: PropTypes.string.isRequired,
};

Audio.defaultProps = {
  small: false,
};


export default Audio;
