import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveAnswer } from '../../../actions';

import LinearQuestion from './linear-question';

import Button from '../../../components/button';
import Question from '../../../components/question';
import Answer from '../../../components/answer';
import Feedback from '../../../components/feedback';
import Footer from '../../../components/footer';
import Progress from '../../../components/progress';
import Options from '../../../components/options';
import Option from '../../../components/option';
import Cloze from '../../../components/Cloze';

const shuffle = (array) => {
  const a = array;
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [array[j], array[i]];
  }
  return array;
};

const deepCopy = object => JSON.parse(JSON.stringify(object));

class LinearQuestionCloze extends LinearQuestion {
  constructor(props, context) {
    super(props, context);

    const selectableOptions = [];
    const clozeOptions = [];
    let keyIndex = 0;

    this.state.options.forEach((option, i) => {
      const { id, text, selected } = option;
      const { option: optionText, locked } = text;
      if (!locked) {
        const opt = ({
          id,
          optionText,
          selected,
          locked,
          subjectClickHandler: () => this.toggle(false, id, optionText),
          answer: false,
        });
        selectableOptions.push({
          option: opt,
          index: -1,
        });
      }
    });

    let hasFirstPlaceholderBeenSet = false;
    this.state.options.sort((a, b) => a.text.order - b.text.order);
    this.state.options.forEach((option) => {
      const { id, text } = option;
      const { option: optionText, locked, order } = text;
      // Ignore if the option isn't in the correct answer
      if (order === -1) {
        return;
      }
      if (locked) {
        clozeOptions.push({
          id,
          keyId: keyIndex += 1,
          optionText,
          selected: false,
          locked,
          subjectClickHandler: () => this.toggle(false, id, optionText),
          answer: false,
        });
      } else {
        clozeOptions.push({
          id: -1,
          keyId: keyIndex += 1,
          optionText: '',
          selected: !hasFirstPlaceholderBeenSet,
          locked,
          subjectClickHandler: keyIndex => this.select(keyIndex),
          answer: false,
        });
        hasFirstPlaceholderBeenSet = true;
      }
    });

    this.state = {
      ...this.state,
      selected: [],
      shuffled: shuffle(JSON.parse(JSON.stringify(this.state.options))),
      optionList: selectableOptions,
      clozeOptions,
    };

    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
  }

  select(indexToSelect) {
    const clozeOptions = [];
    const stateClozeOptionCopy = [...this.state.clozeOptions];

    stateClozeOptionCopy.forEach((option, i) => {
      const mutatedOption = { ...option };
      mutatedOption.selected = false;
      clozeOptions[i] = mutatedOption;
    });
    clozeOptions[indexToSelect].selected = true;

    this.setState({
      clozeOptions,
    });
  }

  toggle(select, id, text) {
    const {
      options,
      answer,
      selected,
      shuffled,
      optionList,
      clozeOptions,
    } = this.state;
    let newSelected = deepCopy(selected);
    if (answer) {
      return;
    }

    const optionIndex = optionList.findIndex(item => item.option.optionText === text && item.option.id === id);

    const findSelected = clozeOptions.findIndex(item => item.selected);

    if (select) {
      // Add the option to the Cloze sentence if there is available space (an cloze option is selected for input)
      if (findSelected !== -1) {
        optionList[optionIndex].index = optionIndex;
        clozeOptions[findSelected] = Object.assign(
          clozeOptions[findSelected], optionList[optionIndex].option,
        );
        clozeOptions[findSelected] = Object.assign(
          clozeOptions[findSelected],
          {
            answer: (
              this.props.correct_answer[findSelected] === clozeOptions[findSelected].optionText
            ),
          },
        );
        const firstSelect = clozeOptions.findIndex(item => item.optionText === '' && item.id === -1);
        if (firstSelect !== -1) clozeOptions[firstSelect].selected = true;
      }
    } else {
      // Remove option from Cloze sentence
      const findOption = clozeOptions.findIndex(item => item.optionText === text && item.id === id);
      clozeOptions.forEach((clozeOption) => {
        clozeOption.selected = false;
      });
      clozeOptions[findOption] = Object.assign(clozeOptions[findOption], {
        id: -1,
        optionText: '',
        selected: true,
        locked: false,
        subjectClickHandler: i => this.select(i),
        answer: false,
      });
    }
    const index = options.findIndex(option => option.text.option === text && option.id === id);
    const sindex = shuffled.findIndex(option => option.text.option === text && option.id === id);

    // If adding an option and the Cloze sentence has space available, mark the option as selected.
    // Or if removing the option, mark the option as deselected.
    if (findSelected !== -1 || !select) {
      options[index].selected = select;
      shuffled[sindex].selected = select;
    }

    if (select) {
      if (findSelected !== -1) {
        newSelected.push(options[index]);
      }
    } else {
      newSelected = selected.filter(option => option.text.option !== options[index].text.option && option.id !== options[index].id);
    }
    const fillableClozeOptions = clozeOptions.filter(option => option.locked === false);

    this.setState({
      options,
      selected: newSelected,
      shuffled,
      clozeOptions,
      ready: (newSelected.length === fillableClozeOptions.length),
    });
  }

  submit() {
    const selected = this.state.clozeOptions.filter(input => (!input.locked && input.id !== -1 ? 1 : 0));
    const filtered = this.props.options.filter(input =>
      // Filtered options are all options that exist in the correct answer and are not locked.
      (!input.locked && input.order > -1));
    filtered.sort((a, b) => a.order - b.order);

    const answer = selected.length === filtered.length
      && selected.every((option, i) => option.optionText === filtered[i].option) ? 'correct' : 'incorrect';
    this.saveAnswer({
      answer: this.state.clozeOptions.map(option => option.optionText),
      isCorrect: answer === 'correct',
    });

    this.setState({ answer });
  }

  reset() {
    if (this.state.answer) {
      return;
    }

    const { options, shuffled, clozeOptions } = this.state;
    const selected = [];

    options.forEach((option, index) => { options[index].selected = false; });
    shuffled.forEach((option, index) => { shuffled[index].selected = false; });
    this.state.clozeOptions.forEach((option, i) => {
      if (!option.locked) {
        clozeOptions[i] = {
          ...option,
          id: -1,
          optionText: '',
          selected: false,
          locked: false,
          subjectClickHandler: i => this.select(i),
          answer: false,
        };
      }
    });

    clozeOptions.forEach(item => item.selected = false);
    const firstSelect = clozeOptions.findIndex(item => item.optionText === '' && item.id === -1);
    clozeOptions[firstSelect].selected = true;

    this.setState({
      ready: false,
      options,
      selected,
      shuffled,
      clozeOptions,
    });
  }

  render() {
    const {
      hint,
    } = this.props;
    const selectableOptions = [];
    const selected = [];

    this.state.selected.forEach((option) => {
      const { id, text } = option;
      const { option: optionText } = text;

      selected.push((<Option
        key={id}
        text={optionText}
        state="neutral"
        compact
        clickHandler={() => this.toggle(false, id, optionText)}
      />));
    });

    this.state.shuffled.forEach((option) => {
      const { id, text, selected: optionSelected } = option;
      const { option: optionText, locked } = text;

      if (!locked) {
        selectableOptions.push((
          <Option
            key={id}
            text={optionText}
            state={optionSelected ? 'disabled' : 'neutral'}
            clickHandler={optionSelected ? () => {
            } : () => this.toggle(true, id, optionText)}
          />
        ));
      }
    });

    return (
      <div>
        <Question>
          <Progress />

          <p className="question__text">
            { this.props.instructions }
          </p>

          <Cloze
            text={this.props.prompt}
            hint={hint}
            subjects={this.state.clozeOptions}
            correct={this.state.answer === 'correct'}
            incorrect={this.state.answer === 'incorrect'}
            resetClickHandler={this.reset}
            correctAnswer={this.props.human_readable_correct_answer}
          >
            { selected }
          </Cloze>

        </Question>

        <Answer>
          <Options>
            { selectableOptions }
          </Options>
          <Feedback display={this.state.answer === 'incorrect'} message={this.props.feedback} />
        </Answer>

        <Footer correct={this.state.answer === 'correct'} incorrect={this.state.answer === 'incorrect'} sticky>
          <Button
            text={this.state.answer ? this.props.submitText : 'Submit'}
            primary
            disabled={!this.state.ready}
            clickHandler={this.state.answer ? this.props.submitClickHandler : this.submit}
          />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  answers: state.answers,
});

const mapDispatchToProps = dispatch => ({
  saveAnswer: answer => dispatch(saveAnswer(answer)),
});

LinearQuestionCloze.propTypes = {
  correct_answer: PropTypes.instanceOf(Array).isRequired,
  instructions: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  submitClickHandler: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearQuestionCloze);
