import { createReducer } from '@reduxjs/toolkit';
import { SET_PROGRESS } from '../actions';

const initialState = {
  percentage: 0,
};

const progress = createReducer(initialState, (builder) => {
  builder.addCase(SET_PROGRESS, (state, action) => ({
    ...state,
    percentage: action.percentage,
  }));
});

export default progress;
