import progress from './reducers/progress';
import api from './reducers/api';
import answers from './reducers/answers';

const reducers = {
  api,
  answers,
  progress,
};

export default reducers;
