import React from 'react';
import PropTypes from 'prop-types';

const style = {
  instantFeedback: {
    textAlign: 'center',
    marginTop: 5,
  },
};

const Droptarget = ({
  correct,
  incorrect,
  children,
  hint,
  correctAnswer,
  resetClickHandler,
}) => (
  <div className="droptarget">
    <div className={`
      droptarget__input
      ${correct ? ' droptarget__input--correct' : ''}
      ${incorrect ? ' droptarget__input--incorrect' : ''}
      `}
    >
      { children }
    </div>

    <div className="droptarget__footer">
      <div className="droptarget__text">
        { hint ? (
          <div>
            <em>Hint:</em>
            {' '}
            { hint }
          </div>
        ) : '' }
      </div>

      <div>
        <button
          href="#"
          className={`droptarget__reset ' + ${children.length && !correct && !incorrect ? '' : 'droptarget__reset--disabled'}`}
          onClick={children.length ? resetClickHandler : () => {}}
        >
          Reset
        </button>
      </div>
    </div>
    { incorrect && (
      <div style={style.instantFeedback}>
        The correct answer is
        {' '}
        <strong>
          {`"${correctAnswer}"`}
        </strong>
      </div>
    )}
  </div>

);

Droptarget.propTypes = {
  correct: PropTypes.bool.isRequired,
  incorrect: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  hint: PropTypes.string,
  correctAnswer: PropTypes.string.isRequired,
  resetClickHandler: PropTypes.func,
};

Droptarget.defaultProps = {
  resetClickHandler: '',
  hint: null,
};

export default Droptarget;
