import React from 'react';
import PropTypes from 'prop-types';

const Prompt = ({ type, children }) => (
  <>
    { type === 'primary' && children.toString().length >= 100 && (
      <p
        className="question__prompt--long"
      >
        { children }
      </p>
    )}
    { type === 'primary' && children.toString().length < 100 && (
      <p
        className="question__prompt"
      >
        { children }
      </p>
    )}
    { type === 'secondary' && (
      <p
        className="question__prompt__secondary"
      >
        { children }
      </p>
    )}
  </>
);

Prompt.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Prompt;
