export const API_PUSH_RESULTS = 'API_PUSH_RESULTS';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SAVE_ANSWER = 'SAVE_ANSWER';

export function apiPushResults() {
  return {
    type: API_PUSH_RESULTS,
  };
}

export function setProgress(percentage) {
  return {
    type: SET_PROGRESS,
    percentage,
  };
}

export function saveAnswer(answer) {
  return {
    type: SAVE_ANSWER,
    answer,
  };
}
