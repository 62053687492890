import React from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';

import AudioVideoQuestionMultiple from './audiovideo/multiple';

import Video from '../../components/Video';
import Button from '../../components/button';
import Buttons from '../../components/buttons';
import Question from '../../components/question';
import Answer from '../../components/answer';
import Footer from '../../components/footer';
import Options from '../../components/options';
import Option from '../../components/option';

const history = createBrowserHistory();

class QuizVideo extends React.Component {
  constructor(props) {
    super(props);

    let question = this.props.questions.findIndex(_question => _question.hashid === this.props.questionHashId);

    if (question === -1) {
      question = null;
    }

    const questions = [];

    this.props.questions.forEach((_question) => {
      questions.push({
        state: 'neutral',
        ..._question,
      });
    });

    this.state = {
      question,
      questions,
    };
  }

  setQuestion(question) {
    this.setState({
      question,
    });
  }

  getQuestion() {
    const question = this.state.questions[this.state.question];
    const answer = this.props.answers.answers[question.hashid] || null;

    const props = {
      submitClickHandler: this.submit.bind(this),
      ...question,
    };

    switch (question.type) {
      case 'MULTIPLE':
      case 'TRUEFALSE':
        return <AudioVideoQuestionMultiple key={question.hashid} answer={answer} {...props} />;
      default:
        return <p>Sorry, something went wrong.</p>;
    }
  }

  submit() {
    this.setQuestion(null);

    // Mark answered questions as completed
    const { questions } = this.state;

    this.state.questions.forEach((question, i) => {
      questions[i].state = this.props.answers.answers[question.hashid] ? 'completed' : 'neutral';
    });

    this.setState({ questions });

    // All questions answered
    if (this.state.questions.every(question => this.props.answers.answers[question.hashid])) {
      this.setState({ ready: true });
    }
  }

  render() {
    // const { audio_transcript: audioTranscript } = this.props;
    const question = this.state.question === null ? null : this.props.questions[this.state.question];

    // Blue body background to match answer section
    document.body.className = this.state.question === null ? '' : 'body--alt';

    history.push(`/p/${this.props.product_slug}/quiz/${this.props.quiz_slug}/in/${this.props.chapter_slug}${question ? `/question/${question.hashid}` : ''}${this.props.isPreview ? '/?preview=true' : ''}`);

    let answer;

    if (this.state.question === null) {
      const options = [];

      this.state.questions.forEach((_question, i) => {
        options.push(<Option key={i} text={_question.prompt} state={_question.state} wide next clickHandler={this.setQuestion.bind(this, i)} />);
      });

      answer = (
        <Options wide>
          { options }
        </Options>
      );
    } else {
      answer = this.getQuestion();
    }

    return (
      <div>
        <Question alt>
          <p className="question__text">
            { this.props.instructions }
          </p>

          <Video hashedId={this.props.video} loadingColor="#eaeaea" />

          <Buttons justify center>
            { this.state.question === null ? <div /> : <Button text="See all" prev clickHandler={this.setQuestion.bind(this, null)} /> }
          </Buttons>
        </Question>

        <Answer active={this.state.question !== null && !this.state.transcriptVisible}>
          { answer }
        </Answer>
        { this.state.question === null ? (
          <p className="footer__note">
            <strong>Note:</strong>
            {' '}
            You must complete
            <em>all</em>
            {' '}
            exercises before you can submit.
          </p>
        ) : '' }

        <Footer correct={this.state.answer === 'correct'} incorrect={this.state.answer === 'incorrect'} sticky>
          <Button text="Finish quiz" primary disabled={!this.state.ready} clickHandler={this.props.submitClickHandler} />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  answers: state.answers,
});

export default connect(mapStateToProps)(QuizVideo);
