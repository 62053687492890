import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveAnswer } from '../../../actions';

import LinearQuestion from './linear-question';

import Button from '../../../components/button';
import Question from '../../../components/question';
import Prompt from '../../../components/Prompt';
import Answer from '../../../components/answer';
import Feedback from '../../../components/feedback';
import Footer from '../../../components/footer';
import Progress from '../../../components/progress';
import Options from '../../../components/options';
import Option from '../../../components/option';
import Droptarget from '../../../components/droptarget';

class LinearQuestionTapAdd extends LinearQuestion {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      selected: [],
    };

    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(i, select) {
    const { options } = this.state;
    let { selected } = this.state;

    if (this.state.answer) {
      return;
    }

    options[i].selected = select;

    if (select) {
      selected.push(options[i]);
    } else {
      selected = selected.filter((option) => option.text !== options[i].text);
    }

    this.setState({
      ready: Boolean(selected.length),
      options,
      selected,
    });
  }

  submit() {
    const { selected } = this.state;

    const answer = (selected.length === this.props.correct_answer.length)
      && selected.every((option, i) => option.text === this.props.correct_answer[i]) ? 'correct' : 'incorrect';

    this.saveAnswer({
      answer: selected.map((option) => option.text),
      isCorrect: answer === 'correct',
    });

    this.setState({ answer });
  }

  reset() {
    if (this.state.answer) {
      return;
    }

    const { options } = this.state;
    const selected = [];

    options.forEach((option, index) => { options[index].selected = false; });

    this.setState({
      ready: false,
      options,
      selected,
    });
  }

  render() {
    const {
      hint,
    } = this.props;
    const { options } = this.state;
    const selected = [];

    this.state.selected.forEach((option) => {
      const i = options.findIndex((o) => o.text === option.text);

      selected.push((<Option
        key={i}
        text={option.text}
        state="neutral"
        compact
        disabled={this.state.answer !== false}
        clickHandler={() => this.toggle(i, false)}
      />));
    });

    return (
      <div>
        <Question>
          <Progress />

          <p className="question__text">
            { this.props.instructions }
          </p>

          <Prompt type="primary">
            {this.props.prompt}
          </Prompt>

          <Droptarget
            hint={hint}
            correct={this.state.answer === 'correct'}
            incorrect={this.state.answer === 'incorrect'}
            resetClickHandler={this.reset}
            correctAnswer={this.props.human_readable_correct_answer}
          >
            { selected }
          </Droptarget>
        </Question>

        <Answer>
          <Options>
            { options.map((option, i) => (
              <Option
                key={option.id}
                text={option.text}
                state={option.selected ? 'disabled' : 'neutral'}
                disabled={this.state.answer !== false}
                clickHandler={option.selected ? () => {} : () => this.toggle(i, true)}
              />
            ))}
          </Options>
          <Feedback display={this.state.answer === 'incorrect'} message={this.props.feedback} />
        </Answer>

        <Footer correct={this.state.answer === 'correct'} incorrect={this.state.answer === 'incorrect'} sticky>
          <Button
            text={this.state.answer ? this.props.submitText : 'Submit'}
            primary
            disabled={!this.state.ready}
            clickHandler={this.state.answer ? this.props.submitClickHandler : this.submit}
          />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswer: (answer) => dispatch(saveAnswer(answer)),
});

LinearQuestionTapAdd.propTypes = {
  correct_answer: PropTypes.instanceOf(Array).isRequired,
  instructions: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  submitClickHandler: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearQuestionTapAdd);
