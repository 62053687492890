import React from 'react';
import PropTypes from 'prop-types';

const Match = ({
  subjects,
  activeSubject,
  optionClickHandler,
  subjectClickHandler,
}) => {
  const lines = [];
  subjects.forEach((subject, i) => {
    const selected = activeSubject === i;
    lines.push((
      <div key={subject.id} className="match__set">
        <div className="match__line">
          <div className="match__subject__column">

            <div
              className={`
          match__subject
          ${selected ? ' match__subject--selected' : ''}
          `}
              role="presentation"
              onClick={() => subjectClickHandler(i)}
            >
              {subject.text}
            </div>
          </div>
          <div className="match__input__column">

            <div
              className={`
          match__input
          ${selected ? ' match__input--selected' : ''}
          ${subject.state === 'correct' ? ' match__input--correct' : ''}
          ${subject.state === 'incorrect' ? ' match__input--incorrect' : ''}
          ${subject.option ? '' : ' match__input--disabled'}
          `}
              role="presentation"
              onClick={() => (subject.option ? optionClickHandler(i, false) : subjectClickHandler(i))}
            >
              {subject.option ? subject.option.text : <br />}

            </div>
          </div>
        </div>
        <div className={`
            match__answer
            ${subject.state === 'incorrect' ? ' match__answer--visible' : ''}
            `}
        >
          The correct answer is &ldquo;
          {subject.correct_answer}
          &rdquo;
        </div>
      </div>
    ));
  });
  return (
    <div className="match">
      {lines}
    </div>
  );
};
Match.propTypes = {
  subjects: PropTypes.instanceOf(Array).isRequired,
  activeSubject: PropTypes.number.isRequired,
  optionClickHandler: PropTypes.func.isRequired,
  subjectClickHandler: PropTypes.func.isRequired,
};

export default Match;
