import React, { Suspense } from 'react';

const ObentoDeluxe = React.lazy(() => import('./ObentoDeluxe'));
const ObentoSupreme = React.lazy(() => import('./ObentoSupreme'));
const FinMarkets = React.lazy(() => import('./FinMarkets'));
const GanzKlasseOne = React.lazy(() => import('./GanzKlasseOne'));
const GanzKlasseTwo = React.lazy(() => import('./GanzKlasseTwo'));
const BimaOne = React.lazy(() => import('./BimaOne'));
const BimaTwo = React.lazy(() => import('./BimaTwo'));
const TapisVolantOne = React.lazy(() => import('./TapisVolantOne'));
const TapisVolantTwo = React.lazy(() => import('./TapisVolantTwo'));
const ParliamoOne = React.lazy(() => import('./ParliamoOne'));
const ParliamoTwo = React.lazy(() => import('./ParliamoTwo'));
const FitForLife78 = React.lazy(() => import('./FitForLife78'));
const FitForLife910 = React.lazy(() => import('./FitForLife910'));
// Attempt to extract product slug from url
// TODO refactor all of this
const matches = window.location.pathname.match(/p\/([^/]+)\//);
const product = matches ? matches[1] : null;

export default () => {
  const loadStyle = () => {
    switch (product) {
      case 'obento-deluxe-5e-oyZU':
        return <ObentoDeluxe />;
      case 'obento-supreme-5e-Imfw':
        return <ObentoSupreme />;
      case 'fin-markets-hunt-terry-0yO8':
        return <FinMarkets />;
      case 'ganz-klasse-1-m2S1':
        return <GanzKlasseOne />;
      case 'ganz-klasse-2-A7Mi':
        return <GanzKlasseTwo />;
      case 'bima-1-gH07':
        return <BimaOne />;
      case 'bima-2-pK3D':
        return <BimaTwo />;
      case 'tapis-volant-1-4e-ji3S':
        return <TapisVolantOne />;
      case 'tapis-volant-2-4e-ko34':
        return <TapisVolantTwo />;
      case 'parliamo-1-SL7M':
        return <ParliamoOne />;
      case 'parliamo-2-EsM4':
        return <ParliamoTwo />;
      case 'fit-for-life-levels-78':
        return <FitForLife78 />;
      case 'fit-for-life-levels-910':
        return <FitForLife910 />;
      default:
        return <></>;
    }
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {loadStyle()}
    </Suspense>
  );
};
