import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({
  correct, incorrect, sticky, children,
}) => {
  const showMessage = () => {
    let message = <div />;

    if (correct) {
      message = (
        <div className="footer__message footer__message--correct">
          <div className="footer__icon footer__icon--correct" />

          <p className="footer__text">
            Correct!
          </p>

          <p className="footer__text footer__text--mobile">
            Correct!
          </p>
        </div>
      );
    }

    if (incorrect) {
      message = (
        <div className="footer__message footer__message--incorrect">
          <div className="footer__icon footer__icon--incorrect" />

          <p className="footer__text">
            Sorry! Wrong answer.
          </p>

          <p className="footer__text footer__text--mobile">
            Wrong answer!
          </p>
        </div>
      );
    }
    return message;
  };

  return (
    <footer className={`
        footer
        ${sticky ? 'footer--sticky' : ''}
        ${correct ? 'footer--correct' : ''}
        ${incorrect ? 'footer--incorrect' : ''}
        `}
    >
      <div className="footer__container">
        {showMessage()}
        {children}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  correct: PropTypes.bool.isRequired,
  incorrect: PropTypes.bool.isRequired,
  sticky: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Footer;
