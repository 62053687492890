function parseQueryStringsInUrl(url) {
  const [, queryString] = url.split('?');

  if (queryString) {
    const pairs = queryString.split('&');
    // If you are wondering why paramas is a constant
    // const declared variables hold read-only references to a value.
    // But it does not mean that the value it holds is immutable
    const params = [];
    for (let i = 0; i < pairs.length; i += 1) {
      const [key, value] = pairs[i].split('=');
      params[key] = value;
    }
    return params;
  }
  return [];
}

export default function urlHasPreviewQueryString(url) {
  if (parseQueryStringsInUrl(url).preview) {
    return true;
  }
  return false;
}
