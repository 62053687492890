import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LinearQuestion from './linear-question';
import Button from '../../../components/button';
import Question from '../../../components/question';
import Prompt from '../../../components/Prompt';
import Answer from '../../../components/answer';
import Feedback from '../../../components/feedback';
import Footer from '../../../components/footer';
import Progress from '../../../components/progress';
import Options from '../../../components/options';
import Option from '../../../components/option';
import Image from '../../../components/Image';

import { saveAnswer } from '../../../actions';

class LinearQuestionMultipleWithImage extends LinearQuestion {
  render() {
    const {
      prompt_secondary: promptSecondary,
    } = this.props;
    const options = [];
    this.selectOption = this.selectOption.bind(this);
    this.submit = this.submit.bind(this);

    this.state.options.forEach((option, i) => {
      options.push((
        <Option
          key={option.id}
          text={option.text}
          state={option.state}
          disabled={this.state.answer !== false}
          clickHandler={() => this.selectOption(i)}
        />
      ));
    });

    return (
      <div>
        <Question>
          <Progress />

          <p className="question__text">
            { this.props.instructions }
          </p>

          <Image uri={this.props.image} alt={this.props.alt_tag} />

          <Prompt type="secondary">
            {promptSecondary}
          </Prompt>

        </Question>

        <Answer>
          <Options>
            { options }
          </Options>
          <Feedback display={this.state.answer === 'incorrect'} message={this.props.feedback} />
        </Answer>

        <Footer correct={this.state.answer === 'correct'} incorrect={this.state.answer === 'incorrect'} sticky>
          <Button
            text={this.state.answer ? this.props.submitText : 'Submit'}
            primary
            disabled={!this.state.ready}
            clickHandler={this.state.answer ? this.props.submitClickHandler : this.submit}
          />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswer: (answer) => dispatch(saveAnswer(answer)),
});

LinearQuestionMultipleWithImage.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearQuestionMultipleWithImage);
