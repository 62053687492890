import React from 'react';
import PropTypes from 'prop-types';

const ShortInput = ({
  onChange,
  value,
  correct,
  incorrect,
  placeholdertext,
  correctAnswer,
  disabled,
}) => (
  <div className="shortinput">
    <textarea
      maxLength="50"
      className={`
      input
      ${correct ? ' shortinput__input--correct' : ''}
      ${incorrect ? ' shortinput__input--incorrect' : ''}
      `}
      onChange={onChange}
      placeholder={placeholdertext}
      value={value}
      disabled={disabled}
    >
      { placeholdertext }
    </textarea>
  </div>
);

ShortInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholdertext: PropTypes.string.isRequired,
  correct: PropTypes.bool.isRequired,
  incorrect: PropTypes.bool.isRequired,
  correctAnswer: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ShortInput.defaultProps = {
  disabled: false,
};

export default ShortInput;
