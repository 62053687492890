import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ text }) => (
  <div className="loading">
    <div className="loading__animation" />

    <div className="loading__text">
      { text || 'Loading...' }
    </div>
  </div>
);

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: '',
};

export default Loading;
