import React from 'react';
import { connect } from 'react-redux';
import LinearQuestion from './linear-question';
import Button from '../../../components/button';
import ShortInput from '../../../components/ShortInput';
import Question from '../../../components/question';
import Answer from '../../../components/answer';
import Feedback from '../../../components/feedback';
import Footer from '../../../components/footer';
import Progress from '../../../components/progress';
import { saveAnswer } from '../../../actions';
import Prompt from '../../../components/Prompt';

const style = {
  instantFeedback: {
    textAlign: 'center',
    marginTop: 5,
    marginBottom: '1rem',
  },
};

class LinearQuestionShortInput extends LinearQuestion {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.state,
      disabled: false,
      inputText: '',
    };
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  onInputChange = (e) => {
    this.setState({
      inputText: e.target.value,
      ready: true,
    });
  };

  /* eslint-disable class-methods-use-this */
  normalizeAnswer = (text) => {
    const punctuationless = text.replace(
      // eslint-disable-next-line no-useless-escape
      /[.,\/#!?$%\^\'&\*;:{}=\-_`~()]/g,
      '',
    );
    const fullPunctuated = punctuationless.replace(
      /\s{2,}/g,
      ' ',
    ).trim();
    return fullPunctuated.toLowerCase();
  };

  submit() {
    const answerText = this.state.inputText;
    let answer = 'correct';
    const answerNormalized = this.normalizeAnswer(
      answerText,
    );
    const isAnswerCorrect = this.props.correct_answer.find(
      // eslint-disable-next-line no-shadow
      (answer) => answer === answerNormalized,
    );
    if (isAnswerCorrect) {
      answer = 'correct';
    } else {
      answer = 'incorrect';
    }
    this.saveAnswer({
      answer: this.state.inputText,
      isCorrect: answer === 'correct',
    });
    this.setState({
      answer,
      ready: false,
      disabled: true,
    });
  }

  reset() {
    if (this.state.answer) {
      return;
    }
    this.setState({
      ready: false,
      inputText: '',
    });
  }

  render() {
    return (
      <div>
        <Question>
          <Progress />
          <p className="question__text">
            {this.props.instructions}
          </p>
          <Prompt type="primary">
            {this.props.prompt}
          </Prompt>
          <Prompt type="secondary">
            {this.props.prompt_secondary}
          </Prompt>
        </Question>

        <Answer>
          <ShortInput
            className="shortinput__input"
            onChange={this.onInputChange}
            value={this.state.inputText}
            correct={this.state.answer === 'correct'}
            incorrect={this.state.answer === 'incorrect'}
            placeholdertext="Type answer here..."
            correctAnswer={this.props.human_readable_correct_answer}
            disabled={this.state.disabled}
          >
            {this.props.placeholdertext}
          </ShortInput>
          <div className="shortinput__footer">

            <div className="shortinput__text">
              <button
                type="button"
                className={`shortinput__reset ' + ${this.state.ready ? '' : 'shortinput__reset--disabled'}`}
                onClick={this.state.answer ? this.props.resetClickHandler : this.reset}
              >
                Reset
              </button>
              {this.state.answer === 'incorrect' && !this.props.feedback && (
                <div style={style.instantFeedback}>
                  The correct answer is
                  {' '}
                  <strong>
                    {`"${this.props.human_readable_correct_answer}"`}
                  </strong>
                </div>
              )}
            </div>
          </div>
          <Feedback
            display={this.state.answer === 'incorrect'}
            message={this.props.feedback}
            correctAnswer={this.props.human_readable_correct_answer}
            narrow
          />
        </Answer>

        <Footer
          correct={
            this.state.answer === 'correct'
          }
          incorrect={
            this.state.answer === 'incorrect'
          }
          sticky
        >

          <Button
            text={
              this.state.answer
                ? this.props.submitText
                : 'Submit'
            }
            primary
            disabled={!this.state.inputText.length}
            clickHandler={
              this.state.answer
                ? this.props.submitClickHandler
                : this.submit
            }
          />

        </Footer>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});
const mapDispatchToProps = (dispatch) => ({
  saveAnswer: (answer) => dispatch(saveAnswer(answer)),
});
LinearQuestionShortInput.propTypes = {};
export default connect(mapStateToProps, mapDispatchToProps)(LinearQuestionShortInput);
