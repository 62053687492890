import TagManager from 'react-gtm-module';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import smoothscroll from 'smoothscroll-polyfill';
import StyleRoutesInjector from './StyleRoutesInjector';
import reducers from './reducers';
import Inspectlet from './modules/Inspectlet';

const store = configureStore({ reducer: reducers });

// GTM
if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs = { gtmId: 'GTM-T33B9QD' };
  TagManager.initialize(tagManagerArgs);
}

// Inspectlet
if (process.env.NODE_ENV === 'production') {
  Inspectlet.inject();
}

// SmoothScroll - polyfill 'smooth' behavior for scrollIntoView()
smoothscroll.polyfill();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <StyleRoutesInjector />
  </Provider>,
);
