import { createReducer } from '@reduxjs/toolkit';
import { SAVE_ANSWER } from '../actions';

const initialState = {
  answers: {},
};

const answers = createReducer(initialState, (builder) => {
  builder.addCase(SAVE_ANSWER, (state, action) => {
    const { answer } = action;

    return {
      ...state,
      answers: {
        ...state.answers,
        [answer.question_hashid]: answer,
      },
    };
  });
});

export default answers;
