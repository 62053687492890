import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title, unit }) => (
  <header className="header">
    <div className="header__logo" />
    <div className="header__title">
      <h1 className="header__title">
        { title }
      </h1>
      <h3 className="unit__title">
        { unit }
      </h3>
    </div>

  </header>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

export default Header;
