import React from 'react';
import {
  Route,
  Routes as Switch,
  BrowserRouter,
} from 'react-router-dom';

import Credits from './components/credits';

import Quiz from './views/quiz';
import NotFound from './views/not-found';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="*" element={<NotFound />} />
      <Route path="/credits" element={<Credits />} />
      <Route path="/p">
        <Route path="*" element={<Quiz {...{ path: '/p' }} />} />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Routes;
