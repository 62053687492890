import React from 'react';
import PropTypes from 'prop-types';

const ResultsMindtap = ({ title, unit }) => (
  <div className="result__mindtap">
    <p className="result__mindtap__title">
      { title }
    </p>
    <p className="result__mindtap__unit__title">
      { unit }
    </p>
    <p className="result__mindtap__greeting">You did it!</p>
    <div className="result__mindtap__logo" />
    <p className="result__mindtap__closing">Please close this window and return to the course.</p>

  </div>
);

ResultsMindtap.propTypes = {
  title: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

export default ResultsMindtap;
