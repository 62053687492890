import React from 'react';
import { useLocation } from 'react-router';

function withLocation(Component) {
  return (props) => (
    <Component props={{ ...props }} location={useLocation()} />
  );
}

export default withLocation;
