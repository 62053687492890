import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const Feedback = ({
  display, message, correctAnswer, narrow,
}) => {
  const scrollToRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        node.style.display = 'block';
        node.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 600);
    }
  }, []);

  if (display && message) {
    return (
      <div ref={scrollToRef} className={`feedback ${narrow ? 'feedback--narrow' : ''}`}>
        {correctAnswer ? (
          <div className="feedback__correct">
            <strong>
              The correct answer is "
              {correctAnswer}
              ".
            </strong>
          </div>
        ) : ''}
        {message.split('\n').map((item, key) => (
          <React.Fragment key={key}>
            {item}
            <br />
          </React.Fragment>
        ))
        }
      </div>
    );
  }
  return null;
};

Feedback.propTypes = {
  display: PropTypes.bool.isRequired,
  message: PropTypes.string,
  correctAnswer: PropTypes.string,
  narrow: PropTypes.bool,
};

Feedback.defaultProps = {
  narrow: false,
};

export default Feedback;
