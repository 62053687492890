import { API_PUSH_RESULTS } from '../actions';

const initialState = null;

function api(state = initialState, action = {}) {
  switch (action.type) {
    case API_PUSH_RESULTS:
      return state;
    default:
      return state;
  }
}

export default api;
