import React from 'react';
import PropTypes from 'prop-types';

const AudioPlayerScrubButton = ({ onClickHandler, onTouchMoveHandler }) => (
  <div
    style={{
      cursor: 'pointer',
    }}
    className="audio__handle"
    onMouseDown={() => onClickHandler()}
    onTouchMove={() => onTouchMoveHandler()}
  />
);

AudioPlayerScrubButton.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  onTouchMoveHandler: PropTypes.func.isRequired,
};

export default AudioPlayerScrubButton;
