import React from 'react';
import PropTypes from 'prop-types';

const style = {
  instantFeedback: {
    textAlign: 'center',
    marginTop: 5,
  },
};

const Cloze = ({
  correct,
  incorrect,
  subjects,
  children,
  hint,
  correctAnswer,
  resetClickHandler,
}) => (
  <div className="cloze">
    { subjects.map((subject, i) => {
      let name = '';
      const {
        optionText,
        locked,
        selected,
        subjectClickHandler,
        answer,
        keyId,
      } = subject;

      if (locked) {
        return (
          <div
            key={keyId}
            className="cloze__locked"
          >
            {optionText}
          </div>
        );
      }
      if (incorrect || correct) {
        name = answer ? 'cloze__input--correct' : 'cloze__input--incorrect';
      }
      return (
        <div
          key={keyId}
          className={`
            cloze__input
            ${selected ? ' cloze__input--selected' : ''}
            ${name}
            ${subject.selected || optionText !== '' ? '' : ' cloze__input--disabled'}
          `}
          role="presentation"
          onClick={() => subjectClickHandler(i)}
        >
          { optionText }
          &nbsp;
        </div>
      );
    })}
    <div className="cloze__footer">
      <div className="cloze__text">
        { hint ? (
          <div>
            <em>Hint:</em>
            {' '}
            { hint }
          </div>
        ) : '' }
      </div>
      <div>
        <button
          type="button"
          className={`cloze__reset ' + ${children.length && !correct && !incorrect ? '' : 'cloze__reset--disabled'}`}
          onClick={children.length && !correct && !incorrect ? resetClickHandler : () => {}}
        >
          Reset
        </button>
      </div>
    </div>
    { incorrect && (
      <div style={style.instantFeedback}>
        The correct answer is
        {' '}
        <strong>
          {`"${correctAnswer}"`}
        </strong>
      </div>
    )}
  </div>
);

Cloze.propTypes = {
  correct: PropTypes.bool.isRequired,
  incorrect: PropTypes.bool.isRequired,
  subjects: PropTypes.instanceOf(Array).isRequired,
  hint: PropTypes.string.isRequired,
  correctAnswer: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  resetClickHandler: PropTypes.func,
};

Cloze.defaultProps = {
  resetClickHandler: '',
};

export default Cloze;
