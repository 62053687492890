import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveAnswer } from '../../../actions';

import AudioVideoQuestion from './audiovideo-question';

import Button from '../../../components/button';
import Buttons from '../../../components/buttons';
import Options from '../../../components/options';
import Option from '../../../components/option';

class AudioVideoQuestionMultiple extends AudioVideoQuestion {
  render() {
    const options = [];
    this.selectOption = this.selectOption.bind(this);
    this.submit = this.submit.bind(this);
    this.state.options.forEach((option, i) => {
      options.push((
        <Option
          key={option.id}
          text={option.text}
          state={option.state}
          center
          clickHandler={() => this.selectOption(i)}
        />));
    });

    return (
      <div>
        <p className="answer__prompt">
          { this.props.prompt }
        </p>

        <Options padded center>
          { options }
        </Options>

        <Buttons center>
          <Button
            text="Save answer"
            disabled={!this.state.ready}
            primary
            clickHandler={this.submit}
          />
        </Buttons>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswer: (answer) => dispatch(saveAnswer(answer)),
});

AudioVideoQuestionMultiple.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioVideoQuestionMultiple);
